import React from "react";
import Style from "styles/components/background_video.module.scss";

const BackgroundVideo = ({ videoSrc }) => (
  <div className={Style.background}>
    <video
      className={Style.video}
      height="100%"
      width="auto"
      poster={videoSrc.poster}
      autoPlay
      loop
      muted
      playsInline
      preload="auto"
    >
      <source src={videoSrc.url} type="video/mp4" />
    </video>
  </div>
);
export default BackgroundVideo;
